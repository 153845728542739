import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import avatar from "assets/img/faces/marc.jpg";

import api from '../../services/api';
import { rotaBase } from '../../services/api';
import Input from '@material-ui/core/Input';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const classes = useStyles();

  const [data, setData] = useState({});
  const [file, setFile] = useState('');

  function onFormSubmit(e) {
    e.preventDefault() // Stop form submit
    fileUpload();
  }

  function onChange(e) {
    setFile(e.target.files[0])
  }

  async function fileUpload() {
    const url = `${rotaBase}/user/avatar`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('user_id', data.id);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }

    await api.post(url, formData, config)
  }

  async function componentDidMount() {
    let email = localStorage.getItem("@hcl-email");
    const responseUser = await api.get(`/user/${email}`);
    const user = responseUser.data[0];

    console.log(user);
    setData(user);
  }

  useEffect(() => {
    componentDidMount();
  }, [])
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Editar Perfil</h4>
              {/* <p className={classes.cardCategoryWhite}>Complete your profile</p> */}
            </CardHeader>

            <CardBody>

              <GridContainer>
                <GridItem xs={12} sm={12} md={5}>
                  <CustomInput
                    //labelText={"Nome"}
                    id="nome"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: data.nome,
                      disabled: true
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    //labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: data.email,
                      disabled: true
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    //labelText="Contato"
                    id="contato"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: data.contato,
                      disabled: true
                    }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={5}>
                  <CustomInput
                    //labelText="Data de Nascimento"
                    id="nascimento"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: data.data_nascimento,
                      disabled: true
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    //labelText="Time"
                    id="time"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: data.time,
                      disabled: true
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    //labelText="Posição"
                    id="posicao"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: !!data.posicao ? data.posicao.nome : "",
                      disabled: true
                    }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    //labelText="Logradouro"
                    id="logradouro"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: data.logradouro,
                      disabled: true
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    //labelText="Bairro"
                    id="bairro"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: data.bairro,
                      disabled: true
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    //labelText="Municipio"
                    id="municipio"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: !!data.municipio ? data.municipio.nome : "",
                      disabled: true
                    }}
                  />
                </GridItem>
              </GridContainer>

              {/* <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <InputLabel style={{ color: "#AAAAAA" }}>About me</InputLabel>
                  <CustomInput
                    labelText="Lamborghini Mercy, Your chick she so thirsty, I'm in that two seat Lambo."
                    id="about-me"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5
                    }}
                  />
                </GridItem>
              </GridContainer> */}

            </CardBody>
            <CardFooter>
              <Button color="primary">Atualizar Perfil</Button>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardAvatar profile>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img src={avatar} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>

              <form onSubmit={onFormSubmit}>
                <input type="file" onChange={onChange} />
                <button type="submit">Upload</button>
              </form>

              <h6 className={classes.cardCategory}>Administrador</h6>
              <h4 className={classes.cardTitle}>{data.nome}</h4>
              {/* <p className={classes.description}>
                Don{"'"}t be scared of the truth because we need to restart the
                human foundation in truth And I love you like Kanye loves Kanye
                I love Rick Owens’ bed design but the back is...
              </p> */}
              {/* <Button color="primary" round>
                Follow
              </Button> */}
            </CardBody>
          </Card>
        </GridItem>

      </GridContainer>
    </div>
  );
}
