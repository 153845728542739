import React, { Component } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import { Table, Form } from "react-bootstrap";

import api from "../../services/api";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  ButtonGroup
} from "reactstrap";

import { InputGroup, InputGroupAddon } from "reactstrap";

export default class Pagamento extends Component {
  state = {
    pagamentos: [],
    payment_released: null,
    loading: false
  };

  async componentDidMount() {
    const response = await api.get(`/userpackages`);
    this.setState({ pagamentos: response.data });

    const { data } = await api.get(`/constants`);
    this.setState({ payment_released: data.payment_released, loading: true });
  }

  async handleUpdate(id) {
    try {
      await api.put(`/userpackages/${id}/payment`, {
        payment: true
      });
      window.location.reload();
    } catch (error) {
      alert("Erro ao salvar");
    }
  }

  async handleDelete(id) {
    try {
      await api.delete(`/userpackages/${id}`);
      window.location.reload();
    } catch (error) {
      alert("Erro ao salvar");
    }
  }

  toggle = () => this.setState({ modalIsOpen: !this.state.modalIsOpen });

  search(text) {
    /* var filter = usuariosAll.filter(value => {
          return (
            String(value[0])
              .toUpperCase()
              .includes(String(text).toUpperCase())
          );
        });
    
        setUsuarios(filter); */
  }

  async handlePeriodPayment() {
    let result = window.confirm("Você deseja continuar ?");

    if (result) {
      if (this.state.payment_released) {
        await api.get(`/finalize_payment`);
      } else {
        await api.get(`/reset_packages`);
      }
    }

    window.location.reload();
  }

  async notification() {
    const { data } = await api.get(`/notifications/packages`);
    alert(data.message);
  }

  render() {
    return (
      <div>
        {this.state.loading && (
          <div style={{ marginBottom: 20 }}>
            {this.state.payment_released ? (
              <button onClick={() => this.handlePeriodPayment()}>
                Fechar Pagamento
              </button>
            ) : (
                <button onClick={() => this.handlePeriodPayment()}>
                  Abrir Pagamento
              </button>
              )}

            {this.state.payment_released &&
              <button
                style={{ marginLeft: 10 }}
                onClick={() => this.notification()}
              >
                Notificar Pagamento
            </button>
            }

          </div>
        )}

        <div id="tabela">
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Nome do Membro</th>
                <th>Pacote Contratado</th>
                <th>Valor do Pacote</th>
                <th>Situação</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.state.pagamentos.map(item => (
                <tr>
                  <td>{item.user.name}</td>
                  <td>{item.package.name}</td>
                  <td>R$ {item.package.price}</td>
                  {/* <td>{String(item.pacote.created_at).split("-")[1] + "/" + String(item.pacote.created_at).split("-")[0]}</td> */}

                  {item.payment != null ? (
                    <td style={{ color: "#080" }}>Pagamento Efetuado</td>
                  ) : (
                      <td style={{ color: "#f00" }}>Pagamento não confirmado</td>
                    )}

                  {/* {item.data_pagamento != null
                                        ?
                                        <td>{String(item.data_pagamento).substr(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("/")}</td>
                                        :
                                        <td></td>
                                    } */}

                  {item.payment != null ? (
                    <td></td>
                  ) : (
                      <td>
                        <button onClick={() => this.handleUpdate(item.id)}>
                          Confirmar Pagamento
                      </button>
                      </td>
                    )}

                  {item.payment != null ? (
                    <td></td>
                  ) : (
                      <td>
                        <button onClick={() => this.handleDelete(item.id)}>
                          Cancelar Pagamento
                      </button>
                      </td>
                    )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}
