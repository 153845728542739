import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import { Form } from "react-bootstrap";

export default function Formulario({ form, sendForm, toggle, type = "Salvar" }) {

    const Validation = Yup.object().shape({
        name: Yup.string()
            .min(2, 'Muito Curto')
            .max(20, 'Muito Longo')
            .required('Campo Obrigatório')
    });

    return (
        <Form>

            <Formik
                validationSchema={Validation}
                validateOnBlur={true}
                validateOnChange={true}
                initialValues={form}
                enableReinitialize={true}
                onSubmit={(values, { resetForm }) => sendForm(values, resetForm)}
                render={({ values, handleSubmit, handleChange, errors, isValid, handleBlur, touched }) => (
                    <>
                        <ModalBody>
                            <Form>
                                <Form.Group>
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        value={values.name}
                                        onBlur={handleBlur('name')}
                                        onChange={handleChange('name')}
                                        error={errors.name && touched.name}
                                        type="text"
                                        placeholder="Nome"
                                    />
                                </Form.Group>

                                {errors.name && <h6 style={{ color: "#f00" }}>{errors.name}</h6>}
                                {errors.submitError && <h6 style={{ color: "#f00" }}>{errors.name}</h6>}
                            </Form>
                        </ModalBody>

                        <ModalFooter>
                            <Button onClick={handleSubmit} variant="primary" type="submit">{type}</Button>

                            <Button color="secondary" onClick={() => toggle()}>Cancelar</Button>
                        </ModalFooter>
                    </>
                )}
            />

        </Form>
    );
}