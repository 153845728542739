import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import api from "../../services/api";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label
} from "reactstrap";

import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";

//import { Form } from "react-bootstrap";
import Form from "./Form";

import InputMask from "react-input-mask";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function TableList(props) {
  const [usuarios, setUsuarios] = useState([]);
  const [usuariosAll, setUsuariosAll] = useState([]);
  const [posicoes, setPosicoes] = useState([]);
  const [permissions, setPermissions] = useState([
    { id: 1, name: "ADMINISTRADOR" },
    { id: 2, name: "MEMBRO" }
  ]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modeSave, setModeSave] = useState(true);

  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [team, setTeam] = useState("");
  const [permission, setPermission] = useState("");
  const [address, setAddress] = useState("");
  const [contact, setContact] = useState("");
  const [position, setPosition] = useState(0);

  useEffect(() => {
    componentDidMount();
  }, []);

  async function componentDidMount() {
    const response = await api.get("/sessions");
    const responseUsers = await api.get("/members");
    /* let result = responseUsers.data.filter(
      item => item.id != response.data.id
    ); */
    let result = responseUsers.data;

    const responsePosicao = await api.get("/positions");
    setPosicoes(responsePosicao.data);

    result = result.map(item => [
      item.name,
      item.address,
      item.team,
      item.contact,
      item.email,
      item.permission,

      <Button
        color="success"
        onClick={() => {
          setId(item.id);
          setName(item.name);
          setEmail(item.email);
          setTeam(item.team);
          setPermission(item.permission);
          setAddress(item.address);
          setContact(item.contact);
          setPosition(item.position_id);

          setModeSave(false);
          setModalIsOpen(true);
        }}
      >
        Atualizar
      </Button>,

      <Button
        color="danger"
        onClick={() => {
          setId(item.id);
          handleDelete(item.id);
        }}
      >
        Deletar
      </Button>
    ]);

    setUsuarios(result);
    setUsuariosAll(result);
  }

  async function handleSave(values, resetForm) {
    try {
      let permissao = document.getElementById("selectPermissao").value;
      let posicao = document.getElementById("selectPosicao").value;

      /* console.log(String(permissao).split("-")[0].trim());
      console.log(String(posicao).split("-")[0].trim());
      console.log(values); */

      await api.post("/users", {
        name: values.name,
        email: values.email,
        password: "hcl@2020",
        package_id: 4,
        team: values.team,
        permission: String(permissao)
          .split("-")[1]
          .trim(),
        address: values.address,
        contact: values.contact,
        position_id: String(posicao)
          .split("-")[0]
          .trim()
      });
      window.location.reload();
    } catch (error) {
      console.log(error);
      alert("Preencha Corretamente os campos");
    }
  }

  async function handleDelete(id) {
    try {
      let r = window.confirm("Você deseja deletar ?");
      if (r == true) {
        await api.delete(`/users/${id}`);
        window.location.reload();
      }
    } catch (error) {
      alert("Erro ao apagar");
    }
  }

  async function handleUpdate(values, resetForm) {
    try {
      let permissao = document.getElementById("selectPermissao").value;
      let posicao = document.getElementById("selectPosicao").value;

      /* console.log(String(permissao).split("-")[1].trim());
      console.log(String(posicao).split("-")[0].trim());
      console.log(values); */

      await api.put(`/users/${id}`, {
        name: values.name,
        email: values.email,
        team: values.team,
        permission: String(permissao)
          .split("-")[1]
          .trim(),
        address: values.address,
        contact: values.contact,
        position_id: Number(
          String(posicao)
            .split("-")[0]
            .trim()
        )
      });
      window.location.reload();
    } catch (error) {
      console.log(error);
      alert("Você não pode atualizar este usuário!");
    }
  }

  function toggle() {
    setModalIsOpen(!modalIsOpen);
  }

  function search(text) {
    var filter = usuariosAll.filter(value => {
      return String(value[0])
        .toUpperCase()
        .includes(String(text).toUpperCase());
    });

    setUsuarios(filter);
  }

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>Usuários</h4>
            <p className={classes.cardCategoryWhite}>
              Usuários cadastrados no sistema
            </p>
          </CardHeader>
          <CardBody>
            <Button
              style={{ marginTop: 20, marginBottom: 20 }}
              color="success"
              onClick={() => {
                setName("");
                setEmail("");
                setTeam("");
                setPermission("");
                setAddress("");
                setContact("");
                setPosition(0);

                setModeSave(true);
                setModalIsOpen(true);
              }}
            >
              Novo Usuário
            </Button>

            {/* <Button
              style={{ marginLeft: 20, marginTop: 20, marginBottom: 20 }}
              color="success"
              onClick={() => inicializarPagamentos()}
            >
              Inicializar Pagamentos
            </Button> */}

            <InputGroup>
              <Input onChange={e => search(e.target.value)} />
              <InputGroupAddon addonType="append">
                <Button color="success">Buscar</Button>
              </InputGroupAddon>
            </InputGroup>

            <Modal isOpen={modalIsOpen} toggle={() => toggle()}>
              <ModalHeader toggle={() => toggle()}>Usuário</ModalHeader>

              {modeSave ? (
                <Form
                  posicoes={posicoes}
                  permissions={permissions}
                  toggle={toggle}
                  form={{
                    name: "",
                    email: "",
                    team: "",
                    permission: "",
                    address: "",
                    contact: "",
                    position: 0
                  }}
                  sendForm={handleSave}
                />
              ) : (
                <Form
                  posicoes={posicoes}
                  permissions={permissions}
                  type={"Atualizar"}
                  toggle={toggle}
                  form={{
                    name,
                    email,
                    team,
                    permission,
                    address,
                    contact,
                    position
                  }}
                  position={position}
                  permission={permission}
                  sendForm={handleUpdate}
                />
              )}
            </Modal>

            <Table
              tableHeaderColor="primary"
              tableHead={[
                "Nome",
                "Endereço",
                "Time",
                "Contato",
                "Email",
                "Perfil",
                "",
                ""
              ]}
              tableData={usuarios}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
