import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import ReactLoading from "react-loading";
import api from "../../services/api";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label
} from "reactstrap";

import { InputGroup, InputGroupAddon, Button, Input } from 'reactstrap';
import { Form } from "react-bootstrap";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function TableList(props) {
  const [usuarios, setUsuarios] = useState([]);
  const [usuariosAll, setUsuariosAll] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    componentDidMount();
  }, []);

  async function componentDidMount() {
    setLoading(true);

    const { id } = props.match.params;

    const response = await api.get(`/matches/${id}`);

    let result = response.data.matche.users.map(item => [
      item.name,
      item.address,
      item.team,
      item.contact,

      item.pivot.confirmation ? (
        <p style={{ color: "#080" }}>Confirmado</p>
      ) : (
          <p style={{ color: "#f00" }}>Não Confirmado</p>
        )
    ]);

    setUsuarios(result);
    setUsuariosAll(result);
    setLoading(false);
  }

  function search(text) {
    var filter = usuariosAll.filter(value => {
      return (
        String(value[0])
          .toUpperCase()
          .includes(String(text).toUpperCase())
      );
    });

    setUsuarios(filter);
  }

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>Usuários da Pelada</h4>
            <p className={classes.cardCategoryWhite}>
              Usuários cadastrados na Pelada
            </p>
          </CardHeader>
          <CardBody>

            <InputGroup>
              <Input onChange={e => search(e.target.value)} />
              <InputGroupAddon addonType="append">
                <Button color="success">Buscar</Button>
              </InputGroupAddon>
            </InputGroup>

            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <ReactLoading color={"#000"} height={"20%"} width={"20%"} />
              </div>
            ) : (
                <Table
                  tableHeaderColor="primary"
                  tableHead={[
                    "Nome",
                    "Endereço",
                    "Time",
                    "Contato",
                    "Confirmado"
                  ]}
                  tableData={usuarios}
                />
              )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
