import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import api from "../../services/api";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label
} from "reactstrap";

import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";
import { Form } from "react-bootstrap";
import InputMask from "react-input-mask";
import MaskedInput from "react-text-mask";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function TableList() {
  const [pacotes, setPacotes] = useState([]);
  const [pacotesAll, setPacotesAll] = useState([]);
  const [id, setId] = useState(0);
  const [name, setNome] = useState("");
  const [price, setValor] = useState("");
  const [days, setDias] = useState("");

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modeSave, setModeSave] = useState(true);

  useEffect(() => {
    componentDidMount();
  }, []);

  async function componentDidMount() {
    const response = await api.get("/packages");

    let result = response.data.map(item => [
      item.name,
      item.price,
      item.days,

      <Button
        color="success"
        onClick={() => {
          setId(item.id);
          setNome(item.name);
          setValor(item.price);
          setDias(item.days);

          setModeSave(false);
          setModalIsOpen(true);
        }}
      >
        Atualizar
      </Button>,

      <Button
        color="danger"
        onClick={() => {
          setId(item.id);
          handleDelete(item.id);
        }}
      >
        Deletar
      </Button>
    ]);

    setPacotes(result);
    setPacotesAll(result);
  }

  async function handleUpdate() {
    try {
      await api.put(`packages/${id}`, {
        name: String(name).toUpperCase(),
        price: String(price).toUpperCase(),
        days: days
      });
      window.location.reload();
    } catch (error) {
      alert("Preencha Corretamente os campos");
    }
  }

  async function handleSave() {
    try {
      await api.post("/packages", {
        name: String(name).toUpperCase(),
        price: String(price).toUpperCase(),
        days: days
      });
      window.location.reload();
    } catch (error) {
      alert("Preencha Corretamente os campos");
    }
  }

  async function handleDelete(id) {
    try {
      let r = window.confirm("Você deseja deletar ?");
      if (r == true) {
        await api.delete(`/packages/${id}`);
        window.location.reload();
      }
    } catch (error) {
      alert("Erro ao apagar");
    }
  }

  function toggle() {
    setModalIsOpen(!modalIsOpen);
  }

  function search(text) {
    var filter = pacotesAll.filter(value => {
      return String(value[0])
        .toUpperCase()
        .includes(String(text).toUpperCase());
    });

    setPacotes(filter);
  }

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>Pacotes</h4>
            <p className={classes.cardCategoryWhite}>
              Pacotes cadastrados no sistema
            </p>
          </CardHeader>
          <CardBody>
            <Button
              style={{ marginTop: 20, marginBottom: 20 }}
              color="success"
              onClick={() => {
                setId(0);
                setNome("");
                setValor("");
                setDias(0);

                setModeSave(true);
                setModalIsOpen(true);
              }}
            >
              Novo Pacote
            </Button>

            <InputGroup>
              <Input onChange={e => search(e.target.value)} />
              <InputGroupAddon addonType="append">
                <Button color="success">Buscar</Button>
              </InputGroupAddon>
            </InputGroup>

            <Modal isOpen={modalIsOpen} toggle={() => toggle()}>
              <ModalHeader toggle={() => toggle()}>Pacote</ModalHeader>
              <ModalBody>
                <Form>
                  <Form.Group>
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      value={name}
                      onChange={e => setNome(e.target.value)}
                      type="text"
                      placeholder="nome"
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Valor</Form.Label>
                    <Form.Control
                      value={price}
                      onChange={e => setValor(e.target.value)}
                      type="text"
                      placeholder="valor"
                    />
                    {/* <InputMask mask="R$ 999,99"
                      value={valor}
                      onChange={e => setValor(e.target.value)}>
                      {(inputProps) => <Form.Control
                        {...inputProps}
                        type="text"
                        placeholder="Valor" />
                      }
                    </InputMask> */}
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Dias por Semana</Form.Label>
                    <Form.Control
                      value={days}
                      onChange={e => setDias(e.target.value)}
                      type="number"
                      placeholder="dias por semana"
                    />
                  </Form.Group>

                  {/* <Form>
                    <div key={`custom-inline-checkbox`} className="mb-3">
                      {segunda ? (
                        <Form.Check
                          custom
                          inline
                          checked
                          label="segunda-feira"
                          type={"checkbox"}
                          id={`segunda-feira`}
                          onClick={() => setSegunda(!segunda)}
                        />
                      ) : (
                          <Form.Check
                            custom
                            inline
                            label="segunda-feira"
                            type={"checkbox"}
                            id={`segunda-feira`}
                            onClick={() => setSegunda(!segunda)}
                          />
                        )}

                      {terca ? (
                        <Form.Check
                          custom
                          inline
                          checked
                          label="terça-feira"
                          type={"checkbox"}
                          id={`terça-feira`}
                          onClick={() => setTerca(!terca)}
                        />
                      ) : (
                          <Form.Check
                            custom
                            inline
                            label="terça-feira"
                            type={"checkbox"}
                            id={`terça-feira`}
                            onClick={() => setTerca(!terca)}
                          />
                        )}

                      {quarta ? (
                        <Form.Check
                          custom
                          inline
                          checked
                          label="quarta-feira"
                          type={"checkbox"}
                          id={`quarta-feira`}
                          onClick={() => setQuarta(!quarta)}
                        />
                      ) : (
                          <Form.Check
                            custom
                            inline
                            label="quarta-feira"
                            type={"checkbox"}
                            id={`quarta-feira`}
                            onClick={() => setQuarta(!quarta)}
                          />
                        )}

                      {quinta ? (
                        <Form.Check
                          custom
                          inline
                          checked
                          label="quinta-feira"
                          type={"checkbox"}
                          id={`quinta-feira`}
                          onClick={() => setQuinta(!quinta)}
                        />
                      ) : (
                          <Form.Check
                            custom
                            inline
                            label="quinta-feira"
                            type={"checkbox"}
                            id={`quinta-feira`}
                            onClick={() => setQuinta(!quinta)}
                          />
                        )}

                      {sexta ? (
                        <Form.Check
                          custom
                          inline
                          checked
                          label="sexta-feira"
                          type={"checkbox"}
                          id={`sexta-feira`}
                          onClick={() => setSexta(!sexta)}
                        />
                      ) : (
                          <Form.Check
                            custom
                            inline
                            label="sexta-feira"
                            type={"checkbox"}
                            id={`sexta-feira`}
                            onClick={() => setSexta(!sexta)}
                          />
                        )}

                      {sabado ? (
                        <Form.Check
                          custom
                          inline
                          checked
                          label="sábado"
                          type={"checkbox"}
                          id={`sábado`}
                          onClick={() => setSabado(!sabado)}
                        />
                      ) : (
                          <Form.Check
                            custom
                            inline
                            label="sábado"
                            type={"checkbox"}
                            id={`sábado`}
                            onClick={() => setSabado(!sabado)}
                          />
                        )}

                      {domingo ? (
                        <Form.Check
                          custom
                          inline
                          checked
                          label="domingo"
                          type={"checkbox"}
                          id={`domingo`}
                          onClick={() => setDomingo(!domingo)}
                        />
                      ) : (
                          <Form.Check
                            custom
                            inline
                            label="domingo"
                            type={"checkbox"}
                            id={`domingo`}
                            onClick={() => setDomingo(!domingo)}
                          />
                        )}
                    </div>
                  </Form> */}
                </Form>
              </ModalBody>
              <ModalFooter>
                {modeSave ? (
                  <Button
                    onClick={() => handleSave()}
                    variant="primary"
                    type="submit"
                  >
                    Salvar
                  </Button>
                ) : (
                  <Button
                    onClick={() => handleUpdate()}
                    variant="primary"
                    type="submit"
                  >
                    Atualizar
                  </Button>
                )}

                <Button color="secondary" onClick={() => toggle()}>
                  Cancelar
                </Button>
              </ModalFooter>
            </Modal>

            <Table
              tableHeaderColor="primary"
              tableHead={["Nome", "Valor", "Dias por Semana", "", ""]}
              tableData={pacotes}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
