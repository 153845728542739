import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input
} from "reactstrap";
import { Form } from "react-bootstrap";
import InputMask from 'react-input-mask';

export default function Formulario({ posicoes, position, permission, permissions, form, sendForm, toggle, type = "Salvar" }) {

    const Validation = Yup.object().shape({
        name: Yup.string()
            .min(5, 'Muito Curto')
            .max(50, 'Muito Longo')
            .required('Campo Obrigatório'),
        email: Yup.string().email("Digite um email válido")
            .required('Campo Obrigatório'),
        team: Yup.string()
            .required('Campo Obrigatório'),
        address: Yup.string()
            .required('Campo Obrigatório'),
        contact: Yup.string()
            .required('Campo Obrigatório'),

    });

    return (
        <Form>

            <Formik
                validationSchema={Validation}
                validateOnBlur={true}
                validateOnChange={true}
                initialValues={form}
                enableReinitialize={true}
                onSubmit={(values, { resetForm }) => sendForm(values, resetForm)}
                render={({ values, handleSubmit, handleChange, errors, isValid, handleBlur, touched }) => (
                    <>
                        <ModalBody>
                            <Form>
                                <Form.Group>

                                    <Form.Group>
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control
                                            value={values.name}
                                            onBlur={handleBlur('name')}
                                            onChange={handleChange('name')}
                                            error={errors.name && touched.name}
                                            type="text"
                                            placeholder="Nome"
                                        />
                                    </Form.Group>
                                    {errors.name && <h6 style={{ color: "#f00" }}>{errors.name}</h6>}

                                    <Form.Label>Logradouro</Form.Label>
                                    <Form.Control
                                        value={values.address}
                                        onBlur={handleBlur('address')}
                                        onChange={handleChange('address')}
                                        type="text"
                                        placeholder="Logradouro"
                                    />
                                    {errors.address && <h6 style={{ color: "#f00" }}>{errors.address}</h6>}


                                    <Form.Label>Time</Form.Label>
                                    <Form.Control
                                        value={values.team}
                                        onBlur={handleBlur('team')}
                                        onChange={handleChange('team')}
                                        type="text"
                                        placeholder="Time"
                                    />
                                    {errors.team && <h6 style={{ color: "#f00" }}>{errors.team}</h6>}

                                    <Form.Group controlId="formGridState">
                                        <Form.Label>Posição</Form.Label>
                                        <Form.Control
                                            id="selectPosicao"
                                            name="selectPosicao"
                                            as="select"
                                        >
                                            {posicoes.map(item =>
                                                position == item.id ? (
                                                    <option selected>{item.id} - {item.name}</option>
                                                ) : (
                                                        <option>{item.id} - {item.name}</option>
                                                    )
                                            )}
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Label>Contato</Form.Label>
                                    <InputMask mask="99 99999 - 9999"
                                        value={values.contact}
                                        onBlur={handleBlur('contact')}
                                        onChange={handleChange('contact')}>
                                        {(inputProps) => <Form.Control
                                            {...inputProps}
                                            type="tel"
                                            placeholder="Contato" />}
                                    </InputMask>
                                    {errors.contact && <h6 style={{ color: "#f00" }}>{errors.contact}</h6>}

                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        value={values.email}
                                        onBlur={handleBlur('email')}
                                        onChange={handleChange('email')}
                                        type="email"
                                        placeholder="Email"
                                    />
                                    {errors.email && <h6 style={{ color: "#f00" }}>{errors.email}</h6>}

                                    <Form.Group controlId="formGridState">
                                        <Form.Label>Permissão</Form.Label>
                                        <Form.Control
                                            id="selectPermissao"
                                            name="selectPermissao"
                                            as="select"
                                        >
                                            {permissions.map(item =>
                                                permission == item.name ? (
                                                    <option selected>{item.id} - {item.name}</option>
                                                ) : (
                                                        <option>{item.id} - {item.name}</option>
                                                    )
                                            )}
                                        </Form.Control>
                                    </Form.Group>

                                </Form.Group>
                            </Form>
                        </ModalBody>

                        <ModalFooter>
                            <Button onClick={handleSubmit} variant="primary" type="submit">{type}</Button>

                            <Button color="secondary" onClick={() => toggle()}>Cancelar</Button>
                        </ModalFooter>
                    </>
                )}
            />

        </Form>
    );
}