import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import api from "../../services/api";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label
} from "reactstrap";

import { InputGroup, InputGroupAddon, Button, Input } from 'reactstrap';
import { Form } from "react-bootstrap";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function TableList() {
  const [peladas, setPeladas] = useState([]);
  const [peladasAll, setPeladasAll] = useState([]);
  const [id, setId] = useState(0);
  const [locale, setLocal] = useState("");
  const [horario, setHorario] = useState("");
  const [date, setData] = useState("");

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modeSave, setModeSave] = useState(true);

  useEffect(() => {
    componentDidMount();
  }, []);

  async function componentDidMount() {
    const response = await api.get("/matches");

    let result = response.data.map(item => [
      item.locale,
      item.date.split("T")[1].substring(0, 5),
      String(item.date)
        .substr(0, 10)
        .split("-")
        .reverse()
        .join("/"),

      <Button
        color="success"
        onClick={() => {
          window.location.href = `/admin/usuariosPelada/${item.id}`;
        }}
      >
        Usuários
      </Button>,

      <Button
        color="success"
        onClick={() => {
          setId(item.id);
          setLocal(item.locale);
          setHorario(item.date.split("T")[1].substring(0, 5));
          setData(item.date.split("T")[0].substring(0, 10));

          setModeSave(false);
          setModalIsOpen(true);
        }}
      >
        Atualizar
      </Button>,

      <Button
        color="danger"
        onClick={() => {
          setId(item.id);
          handleDelete(item.id);
        }}
      >
        Deletar
      </Button>
    ]);

    setPeladas(result);
    setPeladasAll(result);
  }

  async function handleUpdate() {
    //2020-01-28T23:00:00.000Z
    try {
      await api.put(`matches/${id}`, {
        locale: String(locale).toUpperCase(),
        date: String(date) + "T" + String(horario) + ":00.000Z"
      });
      window.location.reload();
    } catch (error) {
      alert("Erro ao salvar");
    }
  }

  async function handleSave() {
    //2020-01-28T23:00:00.000Z
    try {
      await api.post("/matches", {
        locale: String(locale).toUpperCase(),
        date: String(date) + "T" + String(horario) + ":00.000Z"
      });
      window.location.reload();
    } catch (error) {
      alert("Erro ao salvar");
    }
  }

  async function handleDelete(id) {
    try {
      let r = window.confirm("Você deseja deletar ?");
      if (r == true) {
        await api.delete(`/matches/${id}`);
        window.location.reload();
      }
    } catch (error) {
      alert("Erro ao apagar");
    }
  }

  function toggle() {
    setModalIsOpen(!modalIsOpen);
  }

  function search(text) {
    var filter = peladasAll.filter(value => {
      return (
        String(value[0])
          .toUpperCase()
          .includes(String(text).toUpperCase())
      );
    });

    setPeladas(filter);
  }

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>Peladas</h4>
            <p className={classes.cardCategoryWhite}>
              Peladas cadastradas no sistema
            </p>
          </CardHeader>
          <CardBody>
            <Button
              style={{ marginTop: 20, marginBottom: 20 }}
              color="success"
              onClick={() => {
                setLocal("");
                setHorario("");
                setData("");
                setModeSave(true);
                setModalIsOpen(true);
              }}
            >
              Nova Pelada
            </Button>

            <InputGroup>
              <Input onChange={e => search(e.target.value)} />
              <InputGroupAddon addonType="append">
                <Button color="success">Buscar</Button>
              </InputGroupAddon>
            </InputGroup>

            <Modal isOpen={modalIsOpen} toggle={() => toggle()}>
              <ModalHeader toggle={() => toggle()}>Pelada</ModalHeader>
              <ModalBody>
                <Form>
                  <Form.Group>
                    <Form.Label>Local</Form.Label>
                    <Form.Control
                      value={locale}
                      onChange={e => setLocal(e.target.value)}
                      type="text"
                      placeholder="Local"
                    />

                    <FormGroup>
                      <Label for="exampleDate">Data</Label>
                      <Input
                        value={date}
                        onChange={e => setData(e.target.value)}
                        type="date"
                        name="date"
                        id="exampleDate"
                        placeholder="date placeholder"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleTime">Horario</Label>
                      <Input
                        value={horario}
                        onChange={e => setHorario(e.target.value)}
                        type="time"
                        name="time"
                        id="exampleTime"
                        placeholder="time placeholder"
                      />
                    </FormGroup>
                  </Form.Group>
                </Form>
              </ModalBody>
              <ModalFooter>
                {modeSave ? (
                  <Button
                    onClick={() => handleSave()}
                    variant="primary"
                    type="submit"
                  >
                    Salvar
                  </Button>
                ) : (
                    <Button
                      onClick={() => handleUpdate()}
                      variant="primary"
                      type="submit"
                    >
                      Atualizar
                  </Button>
                  )}

                <Button color="secondary" onClick={() => toggle()}>
                  Cancelar
                </Button>
              </ModalFooter>
            </Modal>

            <Table
              tableHeaderColor="primary"
              tableHead={["Local", "Horario", "Data", "Usuários da pelada", "", ""]}
              tableData={peladas}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
