import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import Logo from "../../assets/final.png";
import api from "../../services/api";
import { login, logout } from "../../services/auth";

import { Form, Container } from "./styles";
import ReactLoading from "react-loading";

class Login extends Component {
  state = {
    email: "",
    password: "",
    error: "",
    loading: false
  };

  componentDidMount() {
    logout();
  }

  handleLogin = async e => {
    e.preventDefault();
    const { email, password } = this.state;
    this.setState({ error: "", loading: true });
    if (!email || !password) {
      this.setState({ error: "Preencha e-mail e senha para continuar!" });
    } else {
      try {
        const response = await api.post("/sessions", { email, password });
        login(response.data.token);
        const responseUser = await api.get(`/sessions`);
        if (responseUser.data.permission == "ADMINISTRADOR") {
          this.props.history.push("/admin");
        } else {
          this.setState({
            error:
              "Houve um problema com o login, verifique suas credenciais."
          });
        }
      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o login, verifique suas credenciais."
        });
      }
    }

    this.setState({ loading: false });
  };

  render() {
    return (
      <Container>
        <Form onSubmit={this.handleLogin}>
          <img src={Logo} alt="Airbnb logo" />
          {this.state.error && <p>{this.state.error}</p>}
          <input
            type="email"
            placeholder="Endereço de e-mail"
            onChange={e => this.setState({ email: e.target.value })}
          />
          <input
            type="password"
            placeholder="Senha"
            onChange={e => this.setState({ password: e.target.value })}
          />

          {this.state.loading ? (
            <ReactLoading color={"#fff"} height={"20%"} width={"20%"} />
          ) : (
              <button type="submit">Entrar</button>
            )}

          {/* <hr />
          <Link to="/signup">Criar conta grátis</Link> */}
        </Form>
      </Container>
    );
  }
}

export default withRouter(Login);
