import axios from "axios";
import { getToken } from "./auth";

//export const rotaBase = "http://192.168.1.132:3333";
export const rotaBase = "https://api.hclweb.com.br";

const api = axios.create({
  baseURL: `${rotaBase}`
  //baseURL: "http://127.0.0.1:3333"
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
